"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReadingTypes = exports.TargetSeriesTypes = exports.SeriesType = void 0;
var SeriesType;

(function (SeriesType) {
  SeriesType["AC_STATE"] = "AC_STATE";
  SeriesType["ACCELERATION"] = "ACCELERATION";
  SeriesType["GANTRY_ACCELERATION_STATUS"] = "GANTRY_ACCELERATION_STATUS";
  SeriesType["ACCUMULATION_TIME_S"] = "ACCUMULATION_TIME_S";
  SeriesType["ACTUAL_POINT"] = "ACTUAL_POINT";
  SeriesType["AIR_PRESSURE"] = "AIR_PRESSURE";
  SeriesType["AIR_TEMPERATURE"] = "AIR_TEMPERATURE";
  SeriesType["ANGLE"] = "ANGLE";
  SeriesType["ANGULAR_VELOCITY"] = "ANGULAR_VELOCITY";
  SeriesType["AUTONOMY_STATUS"] = "AUTONOMY_STATUS";
  SeriesType["B"] = "B";
  SeriesType["BATTERY_LEVEL"] = "BATTERY_LEVEL";
  SeriesType["BATTERY_LEVEL_LOW_FROM_LED_CONNECTOR"] = "BATTERY_LEVEL_LOW_FROM_LED_CONNECTOR";
  SeriesType["BATTERY_LEVEL_LOW_FROM_RELAY"] = "BATTERY_LEVEL_LOW_FROM_RELAY";
  SeriesType["BRIGHTNESS"] = "BRIGHTNESS";
  SeriesType["CENTERING_STATUS"] = "CENTERING_STATUS";
  SeriesType["COLOR"] = "COLOR";
  SeriesType["COMPUTED_POSITION"] = "COMPUTED_POSITION";
  SeriesType["CONNECTED_ID"] = "CONNECTED_ID";
  SeriesType["CONNECTED_STATE"] = "CONNECTED_STATE";
  SeriesType["CONTROLLED_VARIABLE"] = "CONTROLLED_VARIABLE";
  SeriesType["CURRENT"] = "CURRENT";
  SeriesType["CURRENT_NAVIGATIONAL_INTENT"] = "CURRENT_NAVIGATIONAL_INTENT";
  SeriesType["DATA_MAP_STATE"] = "DATA_MAP_STATE";
  SeriesType["DEAD_RECKONING"] = "DEAD_RECKONING";
  SeriesType["DEPTH_SWITCH"] = "DEPTH_SWITCH";
  SeriesType["DISTANCE"] = "DISTANCE";
  SeriesType["DOCK_STATUS"] = "DOCK_STATUS";
  SeriesType["DOCKING_STEP"] = "DOCKING_STEP";
  SeriesType["DRIVETRAIN_CONTROL_MODE"] = "DRIVETRAIN_CONTROL_MODE";
  SeriesType["DRIVETRAIN_CONTROL_SIGNAL"] = "DRIVETRAIN_CONTROL_SIGNAL";
  SeriesType["EGRESS_FLOW_10_SECOND_AVERAGE_LM"] = "EGRESS_FLOW_10_SECOND_AVERAGE_LM";
  SeriesType["EGRESS_FLOW_LM"] = "EGRESS_FLOW_LM";
  SeriesType["EGRESS_TOTAL_LITERS_SINCE_RESET"] = "EGRESS_TOTAL_LITERS_SINCE_RESET";
  SeriesType["ENABLED"] = "ENABLED";
  SeriesType["EXTERIOR_FLOW_10_SECOND_AVERAGE_LM"] = "EXTERIOR_FLOW_10_SECOND_AVERAGE_LM";
  SeriesType["EXTERIOR_FLOW_LM"] = "EXTERIOR_FLOW_LM";
  SeriesType["EXTERIOR_TOTAL_LITERS_SINCE_RESET"] = "EXTERIOR_TOTAL_LITERS_SINCE_RESET";
  SeriesType["FAN_STATE"] = "FAN_STATE";
  SeriesType["FIND_LIMIT_SWITCH_STATE"] = "FIND_LIMIT_SWITCH_STATE";
  SeriesType["FIRMWARE_VERSION"] = "FIRMWARE_VERSION";
  SeriesType["FIRMWARE_VERSION_UPDATE"] = "FIRMWARE_VERSION_UPDATE";
  SeriesType["G"] = "G";
  SeriesType["GANTRY_STATE"] = "GANTRY_STATE";
  SeriesType["GRID_MILESTONE_STATE"] = "GRID_MILESTONE_STATE";
  SeriesType["GRID_SQUARE_URL"] = "GRID_SQUARE_URL";
  SeriesType["HAS_CENTERED"] = "HAS_CENTERED";
  SeriesType["HIGH_WATER_STATE"] = "HIGH_WATER_STATE";
  SeriesType["HOME"] = "HOME";
  SeriesType["IP_ADDRESS"] = "IP_ADDRESS";
  SeriesType["INGRESS_FLOW_10_SECOND_AVERAGE_LM"] = "INGRESS_FLOW_10_SECOND_AVERAGE_LM";
  SeriesType["INGRESS_FLOW_LM"] = "INGRESS_FLOW_LM";
  SeriesType["INGRESS_TOTAL_LITERS_SINCE_RESET"] = "INGRESS_TOTAL_LITERS_SINCE_RESET";
  SeriesType["IS_AC_PRESENT"] = "IS_AC_PRESENT";
  SeriesType["IS_CHARGING"] = "IS_CHARGING";
  SeriesType["IS_CONNECTED"] = "IS_CONNECTED";
  SeriesType["IS_HOMED"] = "IS_HOMED";
  SeriesType["IS_PUMP_RUNNING"] = "IS_PUMP_RUNNING";
  SeriesType["LIDAR_STATE"] = "LIDAR_STATE";
  SeriesType["LIFT_STATUS"] = "LIFT_STATUS";
  SeriesType["LIFTER_DIRECTION"] = "LIFTER_DIRECTION";
  SeriesType["LIFTER_POSITION"] = "LIFTER_POSITION";
  SeriesType["LIFTER_SPEED"] = "LIFTER_SPEED";
  SeriesType["LINE"] = "LINE";
  SeriesType["LINE_TYPE"] = "LINE_TYPE";
  SeriesType["LINEAR_VELOCITY"] = "LINEAR_VELOCITY";
  SeriesType["LOGGERS"] = "LOGGERS";
  SeriesType["LOWER_LEVEL_SWITCH"] = "LOWER_LEVEL_SWITCH";
  SeriesType["LF"] = "LF";
  SeriesType["LR"] = "LR";
  SeriesType["MAGNETIC_STRENGTH"] = "MAGNETIC_STRENGTH";
  SeriesType["MAX_PWM"] = "MAX_PWM";
  SeriesType["MAX_WHEEL_SPEED"] = "MAX_WHEEL_SPEED";
  SeriesType["MAXIMUM_REACHED"] = "MAXIMUM_REACHED";
  SeriesType["MINIMUM_REACHED"] = "MINIMUM_REACHED";
  SeriesType["NAME"] = "NAME";
  SeriesType["OCCUPANT_TOWER_ID"] = "OCCUPANT_TOWER_ID";
  SeriesType["OCCUPANT_TUG_ID"] = "OCCUPANT_TUG_ID";
  SeriesType["NUMBER_OF_TIERS"] = "NUMBER_OF_TIERS";
  SeriesType["NUMBER_OF_TOWERS"] = "NUMBER_OF_TOWERS";
  SeriesType["NUMBER_OF_TUGS"] = "NUMBER_OF_TUGS";
  SeriesType["ON_BATTERY"] = "ON_BATTERY";
  SeriesType["ONLINE"] = "ONLINE";
  SeriesType["OUTPUT"] = "OUTPUT";
  SeriesType["OVERFLOW_DANGER_STATE"] = "OVERFLOW_DANGER_STATE";
  SeriesType["OVERRIDE"] = "OVERRIDE";
  SeriesType["PATH"] = "PATH";
  SeriesType["PERCENTAGE"] = "PERCENTAGE";
  SeriesType["POSITION"] = "POSITION";
  SeriesType["PUMP_STATUS"] = "PUMP_STATUS";
  SeriesType["PWM"] = "PWM";
  SeriesType["R"] = "R";
  SeriesType["RELATIVE_HUMIDITY"] = "RELATIVE_HUMIDITY";
  SeriesType["RF"] = "RF";
  SeriesType["ROTATION"] = "ROTATION";
  SeriesType["RPM"] = "RPM";
  SeriesType["RR"] = "RR";
  SeriesType["SET_POINT"] = "SET_POINT";
  SeriesType["SPEED"] = "SPEED";
  SeriesType["SIZE_OF_GRID"] = "SIZE_OF_GRID";
  SeriesType["STATE"] = "STATE";
  SeriesType["SUMP_OVERFLOW_STATE"] = "SUMP_OVERFLOW_STATE";
  SeriesType["TARGET"] = "TARGET";
  SeriesType["TARGET_POSITION"] = "TARGET_POSITION";
  SeriesType["EGRESS_STATE"] = "EGRESS_STATE";
  SeriesType["FILL_CYCLE_STATE"] = "FILL_CYCLE_STATE";
  SeriesType["FILL_CYCLE_TIER_INDEX"] = "FILL_CYCLE_TIER_INDEX";
  SeriesType["FILL_SIGNAL_STATE"] = "FILL_SIGNAL_STATE";
  SeriesType["TIME_TO_EMPTY"] = "TIME_TO_EMPTY";
  SeriesType["TIMER"] = "TIMER";
  SeriesType["TOTAL"] = "TOTAL";
  SeriesType["TYPE"] = "TYPE";
  SeriesType["UPPER_LEVEL_SWITCH"] = "UPPER_LEVEL_SWITCH";
  SeriesType["VALVE_ACTUATOR_STATE"] = "VALVE_ACTUATOR_STATE";
  SeriesType["VALVE_STATE"] = "VALVE_STATE";
  SeriesType["VELOCITY"] = "VELOCITY";
  SeriesType["VERSION"] = "VERSION";
  SeriesType["VOLTAGE"] = "VOLTAGE";
  SeriesType["WATER_FLOW"] = "WATER_FLOW";
  SeriesType["WATER_FLOW_10_SECOND_AVERAGE"] = "WATER_FLOW_10_SECOND_AVERAGE";
  SeriesType["WATER_FLOW_INSTANT"] = "WATER_FLOW_INSTANT";
  SeriesType["WATER_FLOW_TOTAL_VOLUME"] = "WATER_FLOW_TOTAL_VOLUME";
  SeriesType["WATER_LEVEL"] = "WATER_LEVEL";
  SeriesType["WATER_PRESENT"] = "WATER_PRESENT";
  SeriesType["WATER_TEMPERATURE"] = "WATER_TEMPERATURE";
  SeriesType["X"] = "X";
  SeriesType["Y"] = "Y";
  SeriesType["Z"] = "Z";
})(SeriesType = exports.SeriesType || (exports.SeriesType = {}));

var TargetSeriesTypes;

(function (TargetSeriesTypes) {
  TargetSeriesTypes["TARGET_AC_STATE"] = "TARGET_AC_STATE";
})(TargetSeriesTypes = exports.TargetSeriesTypes || (exports.TargetSeriesTypes = {}));

var ReadingTypes;

(function (ReadingTypes) {
  ReadingTypes[ReadingTypes["BOOLEAN"] = 0] = "BOOLEAN";
  ReadingTypes[ReadingTypes["NUMERICAL"] = 1] = "NUMERICAL";
  ReadingTypes[ReadingTypes["STRING"] = 2] = "STRING";
  ReadingTypes[ReadingTypes["ONLINE"] = 3] = "ONLINE";
  ReadingTypes[ReadingTypes["DEBT"] = 4] = "DEBT";
})(ReadingTypes = exports.ReadingTypes || (exports.ReadingTypes = {}));